/* eslint-disable camelcase */
import { get, post }                  from '@rails/request.js'
import { Client }                     from '@twilio/conversations'
import { formatFileSize, formatTime } from '../../frontend/support/format'
import ApplicationController          from '../../frontend/support/application_controller'
import Nitrous                        from '../../frontend/lib/nitrous'
import I18n                           from '../../frontend/support/i18n'

import {
  getTwilioConversationsClient,
  addTwilioEventListeners
} from '../../frontend/lib/twilio/conversations'

import {
  enableElement,
  isScrolledBottom,
  metaContent,
  readFileAsDataUrl,
  scrollTopMax,
  splitFilenameName
} from '../../frontend/support/helpers'

const EVENT_MESSAGE_STYLE = {
  event:              'default',
  state_change_event: 'ruled'
}

export default class extends ApplicationController {

  static targets = [
    'eventTemplate',
    'fileInput',
    'input',
    'inputText',
    'inputFiles',
    'mediaTemplate',
    'messageTemplate',
    'messages',
    'preamble',
    'send',
    'subtitle',
    'title'
  ]

  static values = {
    twilioToken:            String,
    twilioConversationSid:  String,
    participants:           Object,
    currentUserId:          String,
    uploadedFilesUrl:       String,
    consultationNotePdfUrl: String
  }

  static classes = [
    'connecting',
    'sending'
  ]

  initialize() {
    this.isConnected = false
    this.whosTyping  = []
    this.files       = new Map()
  }

  connect() {
    if (this.hasInputTextTarget) this.updateInputSize()
    if (this.hasTwilioConversationSidValue) {
      this.initClient()
    }
  }

  disconnect() {
    if (this.removeConversationEventListeners) {
      this.removeConversationEventListeners()
      delete this.conversation
    }
    if (this.removeClientEventListeners) {
      this.removeClientEventListeners()
    }
    // shut down internal client if token was overridden
    if (this.token) {
      this.#client?.shutdown()
    }
  }

  // ==== Controllers

  // ==== Actions

  /**
   * @param {File} file
   * @param {String} id
   * @returns {object}
   */
  fileValues(file, id) {
    const [basename, extension] = splitFilenameName(file.name)

    return {
      id,
      basename,
      extension,
      class:           '-clearable',
      clear_action:    `${this.identifier}#removeFile`,
      filesize:        formatFileSize(file.size),
      style:           'clinician',
      thumbnail_style: file.type.includes('image/') ? 'image' : 'extension',
      link_action:     `${this.identifier}#noop:prevent`
    }
  }

  selectFile({ currentTarget }) {
    this.keepScroll(() => {
      const { files } = currentTarget
      for (const file of files) {
        const id = crypto.randomUUID()
        this.files.set(id, file)
        const values = this.fileValues(file, id)
        const element = Nitrous.render({
          template: this.mediaTemplateTarget,
          values
        })
        readFileAsDataUrl(file).then((url) => {
          Nitrous.update(element, {
            template: this.mediaTemplateTarget,
            values:   { ...values, thumbnail_url: url }
          })
        })
        this.inputFilesTarget.append(element)
      }
      currentTarget.value = []
    })
  }

  async send(event) {
    if (event instanceof KeyboardEvent) {
      if (event.getModifierState('Shift') || event.getModifierState('Control')) {
        return
      }
      event.preventDefault()
    }

    // TODO: conversation also has a `state` that can be active, inactive, or closed
    //       don't know what they imply yet
    if (this.conversation?.status !== 'joined') return

    this.sendMessage()
  }

  typing() {
    if (this.conversation) {
      this.conversation.typing()
    }
    this.updateInputSize()
  }

  removeFile({ currentTarget }) {
    const fileElement = currentTarget.closest('.c-chat-media')

    this.files.delete(fileElement.id)
    fileElement.remove()
  }

  // Do-nothing action to prevent selected files from opening a useless tab
  noop() {}

  // ==== Getters

  #client = null

  /**
   * @type {import("@twilio/conversations").Client}
   */
  get client() {
    this.#client ??= this.constructClient()
    return this.#client
  }

  get currentUserId() {
    if (this.hasCurrentUserIdValue) return this.currentUserIdValue

    return metaContent('app:user:id')
  }

  get token() {
    if (this.hasTwilioTokenValue) return this.twilioTokenValue

    return undefined
  }

  get inputText() {
    return this.inputTextTarget.value.trim()
  }

  // ==== Setters

  set subtitle(value) {
    this.subtitleTarget.textContent = value
  }

  set inputText(value) {
    this.inputTextTarget.value = value
  }

  // ==== Private

  constructClient() {
    try {
      // create our own local client if the token is overridden
      // used for testing chat in admin
      if (this.token) return new Client(this.token)

      return getTwilioConversationsClient()
    } catch (error) {
      this.captureError(error)

      // return a non-null useless value to prevent memoization from re-calling after an error
      return { connectionState: 'unconstructed' }
    }
  }

  initClient() {
    this.statusConnect(false)

    this.client.onceWithReplay('initialized', () => {
      this.initConversation()
    })

    this.client.onceWithReplay('initFailed', ({ error }) => {
      this.subtitle = I18n.t('chat_component.failed')
      this.captureError(new Error(error.message))
      HF.consoleLog(error.message, {
        display: 'error',
        level:   'error'
      })
    })

    this.removeClientEventListeners = addTwilioEventListeners(this.client, {
      connectionStateChanged: (state) => {
        this.connectionStateChanged(state)
      },

      connectionError: () => {
        this.subtitle    = I18n.t('chat_component.failed')
        this.isConnected = false
      }
    }, this.client)

    this.connectionStateChanged(this.client.connectionState)
  }

  async uploadFiles() {
    const formData = new FormData()

    this.files.forEach((file) => formData.append('chat[files][]', file))

    const response = await post(this.uploadedFilesUrlValue, { body: formData })

    return response.json
  }

  async sendMessage() {
    if (this.inputText.length === 0 && this.files.size === 0) return

    this.statusSending()

    try {
      const attributes = { meta: this.metaAttributes() }
      if (this.files.size > 0) {
        attributes.uploaded_files = await this.uploadFiles()
      }

      await this.conversation.prepareMessage()
        .setBody(this.inputText)
        .setAttributes(attributes)
        .buildAndSend()
    } catch (error) {
      this.captureError(error)
    }

    this.statusSent()

    this.resetInput()
  }

  resetInput() {
    this.files.clear()
    this.inputFilesTarget.innerHTML = ''
    this.inputText = ''
    this.updateInputSize()
    this.inputTextTarget.focus()
    this.scrollToBottom()
  }

  async initConversation() {
    try {
      this.conversation = await this.client.getConversationBySid(this.twilioConversationSidValue)
    } catch (error) {
      this.captureError(error)
      // NOTE: this displays "Forbidden" if the wrong clinician views the consultation
      this.isConnected = false
      this.subtitle = error.message
      return
    }

    this.updateSubtitle()

    this.addPreamble(I18n.t('chat_component.created', { time: formatTime(this.conversation.dateCreated, true) }))

    this.removeConversationEventListeners = addTwilioEventListeners(this.conversation, {
      messageAdded:   (message) => this.addMessage(message),
      messageRemoved: (message) => this.removeMessage(message),
      messageUpdated: ({ message }) => this.updateMessage(message),
      typingEnded:    (participant) => this.typingEnded(participant),
      typingStarted:  (participant) => this.typingStarted(participant)
    })

    try {
      // TODO: future optimisation: only load the latest N messages
      //       and progressive-scroll-up the rest
      const messages = await this.conversation.getMessages(30, undefined, 'forward')
      await this.each(messages, (message) => this.appendMessage(message))
      this.conversation.setAllMessagesRead()
    } catch (error) {
      this.captureError(error)
    }

    this.statusConnect(true)
    this.scrollToBottom()
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   * @returns {Element}
   */
  renderMessage(message) {
    const messageValues = this.messageValues(message)

    const messageElement = Nitrous.renderWithSlots({
      template: this.messageTemplateTarget,
      values:   messageValues,
      slots:    {
        media: [
          ...this.renderMedia(message, messageValues.style),
          ...this.renderUploadedFiles(message, messageValues.style)
        ]
      }
    })

    return messageElement
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   * @returns {Element}
   */
  renderEvent(message) {
    const eventValues = this.eventValues(message)

    const eventElement = Nitrous.render({
      template: this.eventTemplateTarget,
      values:   eventValues
    })

    return eventElement
  }

  /**
   * Render ConsultationNote::Kena::Document uploaded files that were sent via chat
   * (either from patient or clinician)
   * @param {import('@twilio/conversations').Message} message
   * @param {string} style
   * @returns {Element[]}
   */
  renderUploadedFiles(message, style) {
    if (!message.attributes.uploaded_files) return []

    return message.attributes.uploaded_files.map((uploadedFile) => {
      const uploadedFileValues = this.uploadedFileValues(uploadedFile, style)
      const uploadedFileElement = Nitrous.render({
        template: this.mediaTemplateTarget,
        values:   uploadedFileValues
      })

      let fileUrl

      if (uploadedFile.metadata.custom.consultation_note_pdf === 'true') {
        fileUrl = `${this.consultationNotePdfUrlValue}/${uploadedFile.id}`
      } else {
        fileUrl = `${this.uploadedFilesUrlValue}/${uploadedFile.id}`
      }

      get(fileUrl)
        .then((response) => response.json)
        .then(({ file_url, thumbnail_url }) => {
          Nitrous.update(uploadedFileElement, {
            template: this.mediaTemplateTarget,
            values:   { ...uploadedFileValues, file_url, thumbnail_url }
          })
        })

      return uploadedFileElement
    })
  }

  /**
   * Render Twilio-attached media (ConsultationNote PDF "send via chat")
   * @param {import('@twilio/conversations').Message} message
   * @param {string} style
   * @returns {Element[]}
   */
  renderMedia(message, style) {
    const mediaUrlsPromise = this.mediaUrls(message)
    return message.attachedMedia.map((media) => {
      const mediaValues = this.mediaValues(media, style)

      const mediaElement = Nitrous.render({
        template: this.mediaTemplateTarget,
        values:   mediaValues
      })

      mediaUrlsPromise.then((urls) => {
        const url = urls.get(media.sid)
        Nitrous.update(mediaElement, {
          template: this.mediaTemplateTarget,
          values:   { ...mediaValues, file_url: url, thumbnail_url: url }
        })
      })

      return mediaElement
    })
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   */
  appendMessage(message) {
    if (!this.shouldRenderMessage(message)) return

    if (this.isEventMessage(message)) {
      this.messagesTarget.append(this.renderEvent(message))
    } else if (message.author !== 'system') {
      this.messagesTarget.append(this.renderMessage(message))
    }
  }

  /**
   * @param {import("@twilio/conversations").Message} message
   * @returns {string|undefined}
   */
  clinicVisibility(message) {
    return message.attributes?.schema?.clinic_visibility
  }

  /**
   * @param {import("@twilio/conversations").Message} message
   */
  shouldRenderMessage(message) {
    if (this.clinicVisibility(message) === 'hidden') return false

    return true
  }

  /**
   * @param {import("@twilio/conversations").Message} message
   */
  isEventMessage(message) {
    return this.clinicVisibility(message) in EVENT_MESSAGE_STYLE
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   */
  addMessage(message) {
    this.keepScroll(() => {
      this.appendMessage(message)
      this.conversation.setAllMessagesRead()
    })
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   */
  updateMessage(message) {
    const messageElement = this.element.querySelector(`message-${message.sid}`)
    if (!messageElement) return

    if (this.isEventMessage(message)) {
      Nitrous.update(messageElement, {
        template: this.eventTemplateTarget,
        data:     this.eventValues(message)
      })
    } else {
      Nitrous.update(messageElement, {
        template: this.messageTemplateTarget,
        data:     this.messageValues(message)
      })
    }
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   */
  removeMessage(message) {
    this.element.querySelector(`message-${message.sid}`)?.remove()
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   * @returns {object}
   */
  messageValues(message) {
    return {
      id:   `message-${message.sid}`,
      body: message.body,
      time: formatTime(message.dateCreated),
      ...this.authorValues(message)
    }
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   * @returns {object}
   */
  eventValues(message) {
    return {
      id:    `message-${message.sid}`,
      text:  message.body,
      time:  formatTime(message.dateCreated),
      style: EVENT_MESSAGE_STYLE[this.clinicVisibility(message)]
    }
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   * @returns {Promise<Map<string,string>>}
   */
  async mediaUrls(message) {
    if (message.attachedMedia.length === 0) return new Map()
    return message.getTemporaryContentUrlsForAttachedMedia()
  }

  /**
   * @param {import('@twilio/conversations').Media} media
   * @param {string} url
   * @returns {object}
   */
  mediaValues({
    sid,
    filename,
    size,
    contentType
  }, style) {
    return this.baseMediaValues(sid, filename, size, contentType, style)
  }

  uploadedFileValues({
    id,
    filename,
    byte_size,
    content_type
  }, style) {
    return this.baseMediaValues(id, filename, byte_size, content_type, style)
  }

  baseMediaValues(id, filename, size, contentType, style) {
    const [basename, extension] = splitFilenameName(filename)

    return {
      basename,
      extension,
      style,
      filesize:        formatFileSize(size),
      id:              `media-${id}`,
      thumbnail_style: contentType.includes('image/') ? 'image' : 'extension'
    }
  }

  metaAttributes() {
    const currentParticipant = this.participantsValue[this.currentUserId]

    return {
      avatar_url: currentParticipant.avatar,
      name:       currentParticipant.name,
      title:      currentParticipant.title
    }
  }

  /**
   * @param {import('@twilio/conversations').Message} message
   * @returns {object}
   */
  authorValues(message) {
    if (!message.participantSid) {
      return {
        author:       'Kena Health',
        initials:     'KH',
        avatar_style: 'initials',
        style:        'clinician',
        align:        'left'
      }
    }

    const localParticipant = this.findLocalParticipant(message.author)

    return {
      author:       localParticipant.full_name || localParticipant.name,
      initials:     localParticipant.initials,
      avatar_style: localParticipant.avatar ? 'image' : 'initials',
      avatar_url:   localParticipant.avatar,
      style:        localParticipant.type,
      align:        message.author === this.client.user.identity ? 'right' : 'left'
    }
  }

  /**
   * @param {import('@twilio/conversations').Participant} participant
   */
  typingStarted(participant) {
    this.whosTyping.push(participant)
    this.updateSubtitle()
  }

  /**
   * @param {import('@twilio/conversations').Participant} participant
   */
  typingEnded(participant) {
    this.whosTyping = this.whosTyping.filter((p) => p.sid !== participant.sid)
    this.updateSubtitle()
  }

  /**
   * @param {import("@twilio/conversations").ConnectionState} state
   */
  connectionStateChanged(state) {
    switch (state) {
      case 'connecting':
        this.isConnected = false
        this.statusConnect(false)
        break

      case 'connected':
        this.isConnected = true
        if (this.conversation) {
          this.statusConnect(true)
        }
        break

      case 'disconnecting':
        this.subtitle = I18n.t('chat_component.disconnecting')
        break

      case 'disconnected':
        this.subtitle = I18n.t('chat_component.disconnected')
        this.isConnected = false
        break

      case 'denied':
        this.subtitle = I18n.t('chat_component.failed')
        this.isConnected = false
        break

      case 'error':
        this.captureError(new Error('connection state changed to error'))
        break

      default:
        break
    }
  }

  computeSubtitle() {
    if (!this.isConnected && !this.conversation) return I18n.t('chat_component.connecting')
    switch (this.whosTyping.length) {
      case 0: return I18n.t('chat_component.active')
      case 1: return this.typingMessage(this.whosTyping[0])
      default: return I18n.t('chat_component.several_typing')
    }
  }

  /**
   * @param {import('@twilio/conversations').Participant} participant
   */
  typingMessage(participant) {
    const localParticipant = this.findLocalParticipant(participant.identity)
    return I18n.t('chat_component.typing', {
      name: localParticipant.full_name || localParticipant.name
    })
  }

  updateSubtitle() {
    this.subtitle = this.computeSubtitle()
  }

  /**
   * @param {import('@twilio/conversations').Paginator<T>} paginator
   * @param {(value: T, index: number, array: T[]) => void} callback
   */
  async each(paginator, callback) {
    paginator.items.forEach(callback)
    if (paginator.hasNextPage) this.each(await paginator.nextPage(), callback)
  }

  /**
   * @param {import('@twilio/conversations').Participant} participant
   * @returns {object}
   */
  findLocalParticipant(identity) {
    return this.participantsValue[identity]
  }

  scrollToBottom() {
    this.messagesTarget.scrollTo({ behavior: 'instant', top: scrollTopMax(this.messagesTarget) })
  }

  async keepScroll(callback) {
    const wasScrolledBottom = isScrolledBottom(this.messagesTarget)
    await callback()
    if (wasScrolledBottom) this.scrollToBottom()
  }

  updateInputSize() {
    this.keepScroll(() => {
      this.inputTextTarget.style.height = 0
      this.inputTextTarget.style.height = `${this.inputTextTarget.scrollHeight}px`
    })
  }

  enableInputs(value = true) {
    for (const inputAction of this.inputTarget.querySelectorAll('button, textarea, input, a, select')) {
      enableElement(inputAction, value)
    }
  }

  disableInputs() {
    this.enableInputs(false)
  }

  statusConnect(value) {
    this.updateSubtitle()
    this.element.classList.toggle(this.connectingClass, !value)
    this.enableInputs(value)
  }

  statusSending() {
    this.element.classList.add(this.sendingClass)
    this.disableInputs()
  }

  statusSent() {
    this.element.classList.remove(this.sendingClass)
    if (this.isConnected) this.enableInputs()
  }

  addPreamble(text) {
    const div = document.createElement('div')
    div.textContent = text
    this.preambleTarget.appendChild(div)
  }

  sentryContext(_error) {
    return {
      twilio: {
        connectionState:    this.#client?.connectionState,
        conversationSid:    this.twilioConversationSidValue,
        conversationState:  this.conversation?.state,
        conversationStatus: this.conversation?.status,
        participants:       Object.keys(this.participantsValue)
      }
    }
  }

  // ==== Channels

}
